import React from "react";

import { AddCircle } from "../../../../../../../components/icons";

import Card from "../index";

function CreatNew(props) {
  return (
    <Card
      {...props}
      overLayClassName = 'bg-opacity-20 backdrop-blur-2xl'
      Component={() => (
        <AddCircle
          size={64}
          className="text-inherit transition-all ease-in-out duration-300 group-hover:rotate-180"
        />
      )}
      OverlayComponent={() => <>Create New</>}
    />
  );
}

export default CreatNew;
