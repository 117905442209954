import React from 'react'
import Box from '../../../../components/box'
import Form from '../../../../components/form'
import Button from '../../../../components/button'

function SideBar() {
  return (
    <Box className={'w-96 h-full rounded-none border-t-2 overflow-y-scroll'}>
        <h1 className='w-full font-semibold text-slate-500'>Edit Details here</h1>
        <Form control={'input'} style = {'outline'} placeholder = "Heading"/>
        <Form control={'textarea'} controlPropsClassName="h-48" placeholder='Descripiton' style = {'outline'}/>
        <div className='w-full flex justify-end'>
            <Button style={'success'} className={'mr-2 bg-green-600'}>Done</Button>
            <Button style={'danger'}>Cancel</Button>
        </div>
    </Box>
  )
}

export default SideBar