import classNames from "classnames";
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Edit, BackArrow } from "../../../../components/icons";

import Button from "../../../../components/button";
import { toast } from "react-hot-toast";

function EditorHeader({ title, onSave }) {
  const [isEditable, setEditable] = useState(false);
  const navigate = useNavigate();
  const titleRef = useRef();
  return (
    <header className="w-full bg-white px-7 py-5 flex">
      <div className="w-max h-full flex justify-start items-center">
        <button
          onClick={() => {
            navigate(-1);
          }}
          className="p-2 w-max h-max rounded-full transition-all ease-in-out duration-300 hover:bg-slate-100"
        >
          <BackArrow size={20} className="text-slate-600" />
        </button>
      </div>
      <div className="flex-1 col-span-3 font-semibold flex items-center justify-center text-gray-500 text-opacity-70 text-ellipsis overflow-x-hidden">
        <h1
          ref={titleRef}
          className={classNames("text-md px-2 py-1 text-center", {
            "border-b-2 border-b-slate-300": isEditable,
          })}
          onKeyPress={(event) => {
            if (event.target.innerText.length >= 30) {
              event.preventDefault();
              toast.error("Title can have 30 Characters at max");
            }
            if (event.which === 13) {
              event.preventDefault();
              onSave && onSave(titleRef.current.innerText);
              setEditable(false);
            }
          }}
          contentEditable={isEditable}
          onBlur={(event) => {
            onSave && onSave(titleRef.current.innerText);
            setEditable(false);
          }}
        >
          {title}
        </h1>
        {!isEditable && (
          <>
            <button
              className="p-2 rounded-full hover:bg-slate-100 transition-all duration-300 ease-in-out"
              onClick={() => {
                setEditable(true);
              }}
            >
              <Edit className="text-slate-600" />
            </button>
          </>
        )}
        {isEditable && (
          <sub className="text-[10px] font-light text-black ml-2">
            Press <code>Enter</code> to save
          </sub>
        )}
      </div>
      <div className="w-max h-full flex items-center justify-end">
        <div className="lg:flex hidden text-blue-600 text-sm items-center py-2 px-4 bg-blue-300 w-max rounded-full">
          Auto Saving <LoaderSpinner />
        </div>
        <Button style={"dark"} className={"h-full ml-2"}>
          Download
        </Button>
      </div>
    </header>
  );
}

function LoaderSpinner({ className }) {
  return (
    <svg
      aria-hidden="true"
      class={classNames(
        "w-4 h-4 mr-2 text-gray-200 animate-spin bg-inherit fill-blue-600 ml-3",
        className
      )}
      viewBox="0 0 100 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
        className="fill-blue-300"
      />
      <path
        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
        fill="currentFill"
      />
    </svg>
  );
}

export default EditorHeader;
