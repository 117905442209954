import Axios from "axios";

export default function useApi(baseUrl) {
  const axios = Axios.create({
    baseURL: baseUrl,
  });

  async function get(url) {
    try {
      const { data } = await axios.get(url);
      return { error: null, data };
    } catch (e) {
      return { error: e.message, data: null };
    }
  }

  async function post(url, data) {
    try {
      const res = await axios.post(url, data);
      return { error: null, data: res.data };
    } catch (e) {
      return { error: e.message, data: null };
    }
  }

  return {
    get,
    post,
  };
}
