import axios from "axios";

const createApiInstance = (token) =>
  axios.create({
    baseURL: "https://api.mecraft.app",
    timeout: 3000,
    headers: { Authorization: `Bearer ${token}` },
  });

export default createApiInstance;
