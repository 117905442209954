function Pills({ text }) {
    return <></>
}

function FreeTrial() {
  return (
    <div
      draggable
      className="py-2 ml-2 rounded-full px-3 bg-indigo-300 text-white h-max"
    >
      FreeTrial
    </div>
  );
}

Pills.FreeTrial = FreeTrial

export default Pills