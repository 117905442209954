import React, { useEffect } from "react";
import injectStylesToBody from "../../../../utils/externalInjections";

function Viewer({ template }) {
  const { html, css } = template;

  useEffect(() => {
    injectStylesToBody(css)
  }, [css]);

  return (
    <div className="flex-1 overflow-y-scroll p-10">
      <div className=" w-full aspect-a4 bg-white" id="__mecraft_document" dangerouslySetInnerHTML={{__html:html}}/>
    </div>
  );
}

// function WaitingScreen() {
//   return (
//     <div className="w-full h-full items-center justify-center">
//       <PuffLoader />
//     </div>
//   );
// }

export default Viewer;
