import React, { useEffect } from "react";
import Form from "../../../../components/form";
import { useForm } from "react-hook-form";

function Details({ attributes }) {
  const { setNextHandler, setActiveComponent, data, setData } = attributes;
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: { ...data?.personal },
    reValidateMode: "onBlur",
  });

  const onSubmit = (d) => {
    data.personal = d;
    setData(data);
    setActiveComponent("education");
  };

  useEffect(() => {
    const form = document.getElementById("detailForm");
    form.addEventListener("submit", handleSubmit(onSubmit));
    setNextHandler(() => () => {
      form.dispatchEvent(new Event("submit", { cancelable: true }));
    });
    return () => {
      form.removeEventListener("submit", handleSubmit(onSubmit));
    };
  }, []);

  return (
    <form className="w-full h-full" id={"detailForm"}>
      <Form
        control="input"
        style="outline"
        label="Name"
        type="text"
        {...register("name", {
          required: "Name is required",
        })}
        errors={errors}
      />
      <Form
        control="input"
        style="outline"
        label="Email"
        type="email"
        {...register("email", {
          required: "Email is required",
        })}
        errors={errors}
      />
      <Form
        control="input"
        style="outline"
        label="Date Of Birth"
        type="date"
        {...register("dob", {
          required: "D.O.B. is required",
        })}
        errors={errors}
      />
      <Form
        control="input"
        style="outline"
        label="Phone Number"
        type="tel"
        {...register("phone", {
          required: "Phone Number is required",
        })}
        errors={errors}
      />
    </form>
  );
}

export default Details;
