export async function createResume(api, data) {
  try {
    const result = await api.post("/resume", data);
    return { data: result.data, error: null };
  } catch (e) {
    return {
      data: null,
      error: e,
    };
  }
}

export async function updateResume(api, id, data) {
  try {
    const result = await api.put(`/resume?id=${id}`, data);
    return { data: result.data, error: null };
  } catch (e) {
    return {
      data: null,
      error: e,
    };
  }
}

export async function getResume(api) {
  try {
    const result = await api.get("/resume");
    const resume = result.data;
    resume.sort((o1, o2) => {
      if (o1.updatedAt <= o2.updatedAt) {
        return 1;
      }
      return -1;
    });
    return { data: resume, error: null };
  } catch (e) {
    return {
      data: null,
      error: e,
    };
  }
}

export async function deleteResume(api, id) {
  try {
    await api.delete(`/resume?id=${id}`, {});
    return { data: "deleted", error: null };
  } catch (e) {
    return {
      data: null,
      error: e,
    };
  }
}
