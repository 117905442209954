import React, { useEffect, useState } from "react";
import { capitalize } from "lodash";
import Button from "../../components/button";
import { invalidate } from "../../utils/queries";
import { makePayment } from "../../api/payment";
import { toast } from "react-hot-toast";
import classNames from "classnames";

function Plans({ api, user }) {
  useEffect(() => {
    document.addEventListener("visibilitychange", () => {
      if (!document.hidden) {
        invalidate(["profile"]);
      }
    });
  }, []);
  if (user.plan.plan_type === "trial") {
    return (
      <div className="flex flex-1 items-center justify-center lg:p-10 bg-off-white">
        <div className=" bg-white flex flex-col lg:flex-row gap-6 p-10 lg:rounded-xl shadow-lg w-full lg:w-max">
          <PriceCard type={"monthly"} api={api} />
          <PriceCard type={"yearly"} pillText={"Save 16%"} api={api} />
          <PriceCard type={"quarterly"} pillText={"Save 20%"} api={api} />
        </div>
      </div>
    );
  }
  return (
    <div className="flex flex-1 items-center justify-center lg:p-10 bg-off-white">
      <div className="flex-col bg-white flex gap-6 p-10 lg:rounded-xl shadow-lg w-full h-full lg:h-max">
        <PriceCard type={user.plan.plan_type} currentPlan />
        <h1 className="text-[12px] flex-1 text-red-500 mt-0 pt-0">
          If you want to cancel or change your plan, Write us
          an email at <a className="font-mono font-semibold text-red-600" href="mailto:hitenvatsblog@gmail.com">hitenvatsblog@gmail.com</a>. We'll get back to you
          within an hour.
        </h1>
      </div>
    </div>
  );
}

function PriceCard({ type, pillText, api, currentPlan }) {
  const [loading,setLoading] = useState(false)
  async function handlePayment() {
    setLoading(true)
    const { data, error } = await makePayment(api, { plan: type });
    if (error) {
      toast.error("Something went wrong. Try again later :<");
    }
    window.open(data.url);
    setLoading(false)
  }
  return (
    <div
      className={classNames(
        "overflow-hidden border-2 border-indigo-500 rounded-md w-full",
        {
          "lg:w-80": !currentPlan,
        }
      )}
    >
      <h1 className="py-4 flex items-center justify-center text-center font-semibold text-xl text-white w-full border-b-2 bg-indigo-500 border-b-indigo-500">
        {capitalize(type)}
        {pillText && <Pill text={pillText} />}
        {currentPlan && <Pill text={"Your Current Plan"} />}
      </h1>
      <div className="px-4 w-full flex items-center justify-center text-indigo-500 py-2">
        <h1 className="text-6xl font-semibold">
          {TYPE_TO_PRICE[type]} <span className="text-lg">₹</span>
        </h1>
      </div>
      <div
        className={classNames("flex flex-col items-center justify-center", {
          "mb-6": currentPlan,
        })}
      >
        <ul className="py-2 px-4 list-disc">
          {POINTS.map((point, index) => {
            return (
              <li key={index} className="text-sm mt-1 first:mt-0 flex">
                <svg
                  className="flex-shrink-0 w-5 h-5 text-indigo-500 bg-indigo-100 p-1 rounded-full"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <div className="ml-1 w-full text-indigo-500 text-sm">
                  {point}
                </div>
              </li>
            );
          })}
        </ul>
      </div>
      {!currentPlan && (
        <div className="flex w-full justify-center py-4 px-4">
          <Button isLoading={loading} className={" w-full"} onClick={handlePayment}>
            Buy {capitalize(type)} plan
          </Button>
        </div>
      )}
    </div>
  );
}

function Pill({ text }) {
  return (
    <div className="text-sm py-1 px-4 text-indigo-600 w-max bg-indigo-100 rounded-full ml-4">
      {text}
    </div>
  );
}

const POINTS = [
  "Access to a wide range of professional templates to choose from",
  "Unlimited downloads and edits of your resume or CV",
  "5 simultaneous documents ",
  "24/7 support",
  "Personalized AI recommendations",
  "A money-back guarantee if you're not completely satisfied with our service",
  "Option to cancel your subscription at any time with no hidden fees or charges",
];

const TYPE_TO_PRICE = {
  monthly: 199,
  quarterly: 999,
  yearly: 1999,
};

export default Plans;
