import React, { useEffect, useState } from "react";
import Box from "../box";
import { PulseLoader } from "react-spinners";
import Button from "../button";
import { TOKEN } from "../../constants/keys";

const THRESH_HOLD = 5000; // time in ms

export default function WaitForData({ items, children }) {
  const [show, setShow] = useState(false);

  const isLoading = items.reduce((prev, curr) => {
    prev = prev || curr?.isLoading || curr?.loading;
    return prev;
  }, false);

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, THRESH_HOLD);
  }, []);

  if (isLoading) {
    return (
      <Box.Background contentCenter>
        <div className="flex flex-col w-max h-max items-center justify-center">
          <PulseLoader color="#8199FD" />
          <p className="text-[10px] font-semibold text-indigo-400 opacity-70 mt-4">
            Hang on. Content is loading
          </p>
          {show && (
            <>
            <p className="w-56 text-[10px] font-semibold text-center text-red-600 mt-2">If this screen keeps on loading, You can either wait or reset this application by clicking below button</p>
            <Button
              className={"mt-2"}
              onClick={() => {
                localStorage.clear(TOKEN);
                window.location.reload();
              }}
              size="s"
              style="danger"
            >
              Reset Application
            </Button>
            </>
          )}
        </div>
      </Box.Background>
    );
  }

  return <>{children}</>;
}
