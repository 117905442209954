import { gapi } from "gapi-script";

export const googleAuthInit = () => {
  function start() {
    gapi.client.init({
      clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      scope: "",
    });
  }
  gapi.load("client:auth2", start);
};
