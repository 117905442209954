import { useContext, createContext, useState, useEffect } from "react";

import { getItemFromStorage, setItemIntoStorage } from "../../utils/localStorage";
import { TOKEN } from "../../constants/keys";
import createApiInstance from '../../api'

const Context = createContext();

export default function useStorageProvider() {
  return useContext(Context);
}

function StorageProvider({ children }) {
  const [token, setToken] = useState(getItemFromStorage(TOKEN) || null);
  const api = createApiInstance(token)

  function saveToken(token) {
    setItemIntoStorage(token, TOKEN);
    setToken(token);
  }

  function deleteToken(){
    setToken(null)
    setItemIntoStorage(null, TOKEN);
  }

  return (
    <Context.Provider value={{ token, saveToken, api,deleteToken }}>{children}</Context.Provider>
  );
}

export { StorageProvider };
