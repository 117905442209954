import React from "react";
import Text from "../../components/text";

function Profile({ user }) {
  return (
    <div className="flex flex-1 h-0 flex-col lg:flex-row overflow-y-scroll scroll-ms-0 scroll-m-0 lg:overflow-y-clip">
      <div className="flex lg:flex-[1] h-full lg:border-r-2 border-gray-100 justify-center items-center flex-col">
        <img
          src={user.profile_picture}
          alt="profile_picture"
          className=" w-64 rounded-full"
        />
        <h1 className="mt-2 text-gray-800 text-3xl font-semibold">
          {user.name}
        </h1>
        <h1 className="mt-1 text-slate-400">@{user.nickname}</h1>
      </div>
      <div className="flex lg:flex-[2]">Profile Section goes here</div>
    </div>
  );
}

export default Profile;
