import React from "react";
import classNames from "classnames";

import Box from "../../components/box";
import Text from "../../components/text";
import { useOnboarding } from "./hooks/useOnboarding";
import Button from "../../components/button";

import Details from "./screens/Details";
import Education from "./screens/Education";
import Skills from "./screens/Skills";
import Experience from "./screens/Experience";
import Projects from "./screens/Projects";
import Achievements from "./screens/Achievements";
import { find } from "lodash";

function Onboarding({ api, navigate }) {
  const {
    activeComponent,
    nextHandler,
    backHandler,
    finishHandler,
    setActiveComponent,
    loading,
    ...remainingAttributes
  } = useOnboarding({
    components: screens,
    data: null,
  });

  const activeScreen = find(screens, { name: activeComponent });

  return (
    <Box.Background contentCenter>
      <div className="w-max h-max">
        <Box
          className={
            " w-175 min-h-100 h-max overflow-hidden relative flex flex-col p-20"
          }
        >
          <Text.Heading className={""}>{activeScreen.label}</Text.Heading>
          <div className="flex flex-1 h-0 pt-2">
            <activeScreen.component
              attributes={{ ...remainingAttributes, setActiveComponent }}
              api={api}
              navigate={navigate}
            />
          </div>
          <div
            className={classNames(
              "h-1 bg-blue-500 rounded-r-full absolute left-0 bottom-0 transition-all duration-300 ease-in-out",
              {
                "w-1/6": activeScreen.name === "details",
                "w-2/6": activeScreen.name === "education",
                "w-3/6": activeScreen.name === "skills",
                "w-4/6": activeScreen.name === "experience",
                "w-5/6": activeScreen.name === "projects",
                "w-full": activeScreen.name === "achievements",
              }
            )}
          ></div>
        </Box>
        <div className="w-full flex justify-end gap-2 mt-2">
          <Button
            disabled={activeComponent === "details"}
            style={"outline_primary"}
            onClick={() => {
              const idx = screens.findIndex(
                ({ name }) => name === activeComponent
              );
              setActiveComponent(screens[idx - 1].name);
            }}
          >
            {"< Back"}
          </Button>
          {!activeScreen.last && (
            <Button onClick={nextHandler}>{"Next >"}</Button>
          )}
          {activeScreen.last && (
            <Button
              onClick={finishHandler}
              style={"success"}
              isLoading={loading}
            >
              Submit
            </Button>
          )}
        </div>
      </div>
    </Box.Background>
  );
}

const screens = [
  {
    label: "Personal Details",
    component: Details,
    name: "details",
    last: false,
  },
  {
    label: "Education",
    component: Education,
    name: "education",
    last: false,
  },
  {
    label: "Skills",
    component: Skills,
    name: "skills",
    last: false,
  },
  {
    label: "Experience",
    component: Experience,
    name: "experience",
    last: false,
  },
  {
    label: "Projects",
    component: Projects,
    name: "projects",
    last: false,
  },
  {
    label: "Achievements",
    component: Achievements,
    name: "achievements",
    last: true,
  },
];

export default Onboarding;
