export async function getUserProfile(api) {
  return new Promise((resolve, reject) =>
    api
      .get("/auth/profile")
      .then(({ data: { data } }) => resolve({ ...data }))
      .catch((e) => reject(e))
  );
}

export async function updateUser(api, data) {
  return new Promise((resolve, reject) => {
    api
      .put("/auth/profile", { data: JSON.stringify(data) })
      .then(({ data }) => resolve(data))
      .catch((e) => reject(e));
  });
}
