import React, { Fragment } from "react";

import logo from "./assets/logo.png";
import Button from "../button";
import { Menu, Transition } from "@headlessui/react";
import SignOut from "./components/signOutButton";
import Pills from "../pills";
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";
import { capitalize } from "lodash";
import { formateFromNow } from "../../utils/date";

function Header({ user, navigate, className }) {
  const location = useLocation();
  let shouldDetailsShown, shouldHeaderShown;
  switch (location.pathname) {
    case "/profile":
      shouldDetailsShown = false;
      shouldHeaderShown = true;
      break;
    case "/success":
      shouldDetailsShown = false;
      shouldHeaderShown = true;
      break;
    case "/onboarding":
      shouldHeaderShown = false;
      break;
    default:
      shouldDetailsShown = true;
      shouldHeaderShown = true;
  }

  if (!shouldHeaderShown) {
    return <></>;
  }

  return (
    <>
      <div
        className={classNames(
          "w-full py-3 px-2 lg:px-10 lg:py-7 bg-white flex justify-between",
          className
        )}
      >
        <div className="flex items-center">
          <a href={"/"}>
            <img src={logo} className="h-10" alt="logo" />
          </a>
          {user && user.plan.on_free_trial && <Pills.FreeTrial />}
        </div>
        {shouldDetailsShown && (
          <div className="flex items-center">
            {user.plan.plan_type !== "trial" && (
              <Subscription plan={user.plan} />
            )}
            <Button.Premium className={"hidden sm:flex mr-2"} user={user} />
            <Menu as={Fragment}>
              <Menu.Button className={"flex items-center justify-center"}>
                <img
                  src={user.profile_picture}
                  alt="profile"
                  className="h-10 cursor-pointer rounded-full ring-0 hover:ring-4 hover:ring-purple-200 transition-all duration-300 ease-in-out"
                />
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-20 mt-28 mr-1 w-max origin-bottom-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  {MENU_ITEMS.map(({ Component }, index) => (
                    <Component key={index} navigate={navigate} user={user} />
                  ))}
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        )}
      </div>
      <div className="w-full h-0.5 bg-gray-500 opacity-10" />
    </>
  );
}

function Subscription({ plan }) {
  return (
    <div className="mr-2 gap-2 flex">
      <div className="bg-emerald-300 hidden lg:flex text-emerald-700 font-semibold text-sm py-2 px-4 rounded-full cursor-pointer shadow-transparent hover:shadow-md transition-all ease-in-out duration-300">
        Renew {formateFromNow(plan.charge_time)}
      </div>
      <Link to={"/plans"}>
        <div className=" bg-cyan-300 text-cyan-700 font-semibold text-sm py-2 px-4 rounded-full cursor-pointer shadow-transparent hover:shadow-md transition-all ease-in-out duration-300">
          {capitalize(plan.plan_type)} plan
        </div>
      </Link>
    </div>
  );
}

const MENU_ITEMS = [
  {
    label: "SignOut",
    Component: SignOut,
  },
  {
    label: "Upgrade",
    Component: ({ user }) => {
      return (
        <Button.Premium
          user={user}
          className={"flex-1 justify-center m-2 sm:hidden"}
        />
      );
    },
  },
  {
    label: "Details",
    Component: ({ navigate }) => {
      return (
        <Button
          style={"transparent"}
          onClick={() => {
            navigate("/profile");
          }}
          className={"w-full"}
        >
          Profile
        </Button>
      );
    },
  },
];

export default Header;
