import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";

import Button from "../../../../components/button";
import { Cross } from "../../../../components/icons";
import Form from "../../../../components/form";

function Projects({ attributes }) {
  const { setActiveComponent, setNextHandler, data, setData } = attributes;
  const [projects, setProjects] = useState(data?.projects || []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    setNextHandler(() => () => {
      if (projects.length >= 1) {
        data.projects = projects;
        setData(data);
        setActiveComponent("achievements");
      } else {
        toast.error("There should be atleast one Project");
      }
    });
  }, [projects]);

  return (
    <div className="h-full w-full">
      {projects.map(({ name }, idx) => (
        <div
          className="w-full p-2 rounded mb-2 bg-slate-50 flex justify-between items-center"
          key={idx}
        >
          {name}
          <div>
            <Cross
              className="hover:bg-gray-200 duration-300 transition-all cursor-pointer"
              onClick={() => {
                setProjects(projects.filter((elem, i) => i !== idx));
              }}
            />
          </div>
        </div>
      ))}
      <form
        className="w-full rounded bg-slate-100 p-2"
        onSubmit={handleSubmit((d) => {
          setProjects([...projects, d]);
          reset();
        })}
      >
        <Form
          control="input"
          style="outline"
          label="Name"
          {...register("name", {
            required: "Name is required",
          })}
          errors={errors}
        />
        <Form
          control="textarea"
          style="outline"
          label="Description"
          {...register("description", {
            required: "Description is required",
          })}
          className="max-h-60"
          helpText="Enter very basic description. You'll be able to change it through AI."
          errors={errors}
        />
        <div className="flex justify-end gap-2">
          <Button type="submit">Add Project</Button>
        </div>
      </form>
    </div>
  );
}

export default Projects;
