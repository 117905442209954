import React from "react";

import Modal from "..";
import Button from "../../button";

export default function ConfirmationModal({
  title,
  isOpen,
  text,
  onClose,
  onSuccess,
}) {
  return (
    <Modal
      title={title}
      ModalComponent={
        <div>
          <div className="py-3 text-sm">{text}</div>
          <div className="flex">
            <Button
            style='success'
             onClick={()=>{
                onSuccess()
                onClose()
            }}>Confirm</Button>
            <Button onClick={onClose} style="danger" className={'ml-2'}>
              Cancel
            </Button>
          </div>
        </div>
      }
      closeModal={onClose}
      isOpen={isOpen}
    />
  );
}
