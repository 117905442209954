import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import Card from "../index";
import { formateFromNow } from "../../../../../../../utils/date";
import ConfirmationModal from "../../../../../../../components/modal/confirmation";
import { Edit,Delete } from "../../../../../../../components/icons";

function ResumeCard({ doc, onDelete }) {
  const [modalOpen,setOpen] = useState(false)
  const navigate = useNavigate()
  return (
    <div>
      <Card
        Component={() => (
          <div className="flex w-full h-full relative">
            <span className="w-full overflow-clip flex justify-center items-center p-4">
              <p className="text-ellipsis text-xl font-semibold ">
                {doc.title}
              </p>
            </span>
            <span className="absolute bottom-1 right-2 text-xs font-semibold text-gray-500 text-opacity-70">
              Last updated {formateFromNow(doc.updatedAt)}
            </span>
          </div>
        )}
        overLayClassName="bg-indigo-200 bg-opacity-20 backdrop-blur"
        OverlayComponent={() => (
          <div>
            <button className="p-2 w-max h-max rounded-full bg-white" onClick={()=>{
              navigate('/editor',{
                state: doc
              })
            }}>
              <Edit />
            </button>
            <button onClick={()=>{ setOpen(true) }} className="p-2 w-max h-max rounded-full bg-red-600 ml-2">
              <Delete className="text-white" />
            </button>
          </div>
        )}
        className=" ring-2 ring-indigo-400 ring-opacity-20 hover:ring-indigo-500 hover:ring-opacity-20  bg-indigo-200 bg-opacity-20 border-none"
      />
      <ConfirmationModal title={'Document Deletion Confirmation'} text={`Are you sure you want to delete doc with title "${doc.title}"`} onClose={()=>{ setOpen(false) }} onSuccess={onDelete} isOpen={modalOpen}/>
    </div>
  );
}

export default ResumeCard;
