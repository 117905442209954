import classNames from 'classnames'
import React from 'react'

function Card({ Component, OverlayComponent,className, overLayClassName, ...props }) {
  return (
    <div {...props} className={classNames(
      'w-full aspect-square rounded-lg flex justify-center transition-all ease-in-out duration-300 items-center ring-2 hover:ring-4 ring-blue-300 overflow-hidden text-blue-500 cursor-pointer group relative',
      className
    )}>
        <Component/>
        <div className={classNames(
          'w-full h-full bg-blue-300 flex justify-center items-center text-2xl font-semibold absolute opacity-0 group-hover:opacity-100 transition-all ease-in-out duration-300',
          overLayClassName
        )}>
            <OverlayComponent/>
        </div>
    </div>
  )
}

export default Card