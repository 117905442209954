import { identity } from "lodash";
import { useState } from "react";

export function useOnboarding({ components: COMPONENTS, prevData }) {
  const [activeComponent, setActiveComponent] = useState(COMPONENTS[0].name);
  const [nextHandler, setNextHandler] = useState(identity);
  const [backHandler, setBackHandler] = useState(identity);
  const [finishHandler, setFinishHandler] = useState(identity);
  const [data, setData] = useState(prevData || {});
  const [loading, setLoading] = useState(false);
  return {
    activeComponent,
    setActiveComponent,
    nextHandler,
    setNextHandler,
    backHandler,
    setBackHandler,
    finishHandler,
    setFinishHandler,
    data,
    setData,
    loading,
    setLoading,
  };
}

/*
{
    BasicDetails: [ name, email, phone, dob ],
    Education: [],
    Skills: [],
    Experience: [],
    Projects: [],
    Achievements: []
}
*/
