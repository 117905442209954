import classNames from 'classnames'
import React from 'react'

function Text({ children,className }) {
  return (
    <h1 className={classNames(
        'text-sm font-medium lg:text-md',
        className
    )}>{children}</h1>
  )
}

function Heading({ children ,className}){
    return(
        <h1 className={classNames(
            'text-xl font-semibold lg:text-2xl',
            className
        )}>{children}</h1>
    )
}

Text.Heading = Heading

export default Text