import toast from "react-hot-toast";

import Form from "../../form";
import Modal from "../index";
import Button from "../../button";
import { useForm } from "react-hook-form";
import { createResume } from "../../../api/resume";
import { invalidate } from "../../../utils/queries";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

function CreateNewDoc({ isOpen, closeModal,api }) {
  return (
    <Modal
      isOpen={isOpen}
      closeModal={closeModal}
      ModalComponent={<CreateForm closeModal={closeModal} api={api} />}
      title={"Create New Document"}
    />
  );
}

function CreateForm({ closeModal,api }) {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const navigate = useNavigate()
  const [isLoading,setLoading] = useState(false)
  async function createResumeRequest(data){
    setLoading(true)
    const res = await createResume(api,data)
    if(res.data){
      invalidate(["resume"])
      toast.success('Document created')
      navigate('/editor',{
        state: res.data
      })
      closeModal()
    } 
    if(res.error){
      toast.error('Something went wrong')
    }
    setLoading(false)
  } 
  
  return (
    <div className="flex w-72 flex-col mt-2">
      <Form
        {...register("title",{
          required: "Title is required"
        })}
        control={"input"}
        label="Title of Document"
        errors={errors}
      />
      <div className="flex mt-2">
        <Button
          onClick={handleSubmit((data) => {
            createResumeRequest(data)
          })}
          style={'primary'}
          isLoading={isLoading}
        >
          Create
        </Button>
        <Button
          style='danger'
          className="ml-2"
          onClick={closeModal}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
}

export default CreateNewDoc;
