import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { useQuery } from "@tanstack/react-query";

import CreatNew from "./components/card/create";
import CreateNewDocModal from "../../../../components/modal/createModal";
import { deleteResume, getResume } from "../../../../api/resume";
import WaitForData from "../../../../components/waitForData";
import ResumeCard from "./components/card/existing";
import { invalidate } from "../../../../utils/queries";
import { RESUME_PER_FREE_TRIAL, RESUME_PER_PLAN } from "../../../../constants/resume";

function Personal({ api,user,resumeQuery }) {
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <WaitForData items={[resumeQuery]}>
      <section className="w-full h-max">
      <div className="w-full mb-2 h-full grid gap-2 grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 px-2 grid-rows-1">
        <CreatNew
          onClick={() => {
            const noOfDocs = resumeQuery.data?.data.length
            if(noOfDocs >= RESUME_PER_FREE_TRIAL && user.plan.on_free_trial){
              toast.error(`You can't create more than 1 doc on free plan`)
              return
            }
            if(RESUME_PER_PLAN <= noOfDocs) {
              toast.error("You can't have more than 5 docs on your current plan")
              return
            }
            setModalOpen(true);
          }}
        />
        {
          resumeQuery.data?.data.map((doc,index)=>(
            <ResumeCard doc={doc} key={index} onDelete={async()=>{
                const {error} = await deleteResume(api,doc._id)
                if(error){
                  toast.error('Something went wrong.')
                } else {
                  invalidate(["resume"])
                  toast.success("Deletion Successfull")
                }
              }
            }/>
          ))
        }
      </div>
      </section>
      <CreateNewDocModal
        isOpen={modalOpen}
        closeModal={() => {
          setModalOpen(false);
        }}
        api={api}
      />
    </WaitForData>
  );
}

export default Personal;
