import React from "react";
import Box from "../../components/box";
import Text from "../../components/text";

function FailureScreen() {
  return (
    <Box.Background contentCenter className={"bg-red-200"}>
      <Box
        className={
          "bg-red-600 p-10 h-screen w-full sm:w-max sm:h-max sm:rounded-xl shadow-xl flex flex-col justify-center items-center"
        }
        overRideCurrentClassNames
      >
        <div>
          <Text.Heading className={"text-white"}>Login Failure</Text.Heading>
          <Text className={"text-red-50"}>
            {"Something wrong happened. Try again later"}
          </Text>
        </div>
      </Box>
    </Box.Background>
  );
}

export default FailureScreen;
