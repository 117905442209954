import classNames from "classnames";
import React from "react";
import { Link } from "react-router-dom";

import { Crown } from "../../icons";

function Premium({ className, user, ...props }) {
  const onTrial = user.plan.on_free_trial
  return (
    <>
      {onTrial && (
        <Link to={"/plans"} className="flex">
          <button
            {...props}
            className={classNames(
              "px-3 py-2 text-sm bg-gradient-to-bl from-orange-400 to-yellow-500 flex items-center text-white font-semibold rounded transition-all duration-300 ease-in-out group focus:ring-2 focus:ring-yellow-100 cursor-pointer",
              className
            )}
          >
            <Crown className="mr-2 transition-all duration-300 ease-in-out group-hover:translate-x-14" />
            <span className="group-hover:opacity-0 transition-all duration-200 ease-in-out">
              Upgrade Now
            </span>
          </button>
        </Link>
      )}
    </>
  );
}

export default Premium;
