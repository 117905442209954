import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";

import Form from "../../../../components/form";
import Button from "../../../../components/button";
import { capitalize } from "lodash";
import { toast } from "react-hot-toast";
import { Cross } from "../../../../components/icons";

function Education({ attributes }) {
  const {
    setActiveComponent,
    setNextHandler,
    data,
    setData: setUserData,
  } = attributes;
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [educationalData, setData] = useState(data?.education || []);

  useEffect(() => {
    setNextHandler(() => () => {
      if (educationalData.length >= 1) {
        data.education = educationalData;
        setUserData(data);
        setActiveComponent("skills");
      } else {
        toast.error("There should be atleast one Education");
      }
    });
  }, [educationalData]);

  return (
    <div className="w-full h-full">
      {educationalData.map(({ name, college }, idx) => (
        <div
          className="w-full p-2 rounded mb-2 bg-slate-50 flex flex-row items-center justify-between"
          key={idx}
        >
          <div>
            {capitalize(name)}{" "}
            <span className="text-xs text-slate-500">{`(${capitalize(
              college
            )})`}</span>
          </div>
          <div className="flex items-center justify-center">
            <Cross
              className="cursor-pointer hover:bg-gray-200 bg-transparent duration-300 transition-all"
              onClick={() => {
                setData(educationalData.filter((elem, i) => idx !== i));
              }}
            />
          </div>
        </div>
      ))}
      <DetailsForm
        errors={errors}
        register={register}
        handleSubmit={handleSubmit((data) => {
          setData([...educationalData, data]);
          reset();
        })}
      />
    </div>
  );
}

function DetailsForm({ errors, register, handleSubmit }) {
  return (
    <form className="w-full rounded bg-slate-100 p-2" onSubmit={handleSubmit}>
      <Form
        control="input"
        style="outline"
        label="Degree/Course Name"
        {...register("name", {
          required: "Degree is required",
        })}
        errors={errors}
      />
      <Form
        control="input"
        style="outline"
        label="College/Institute Name"
        {...register("college", {
          required: "College is required",
        })}
        errors={errors}
      />
      <div className="flex w-full gap-2">
        <Form
          control="input"
          style="outline"
          label="CGPA/Percentage"
          {...register("marks", {
            required: "Marks is required",
          })}
          errors={errors}
        />
        <Form
          control="input"
          style="outline"
          label="Starting Date"
          type="date"
          {...register("startDate", {
            required: "Start Date is required",
          })}
          errors={errors}
        />
        <Form
          control="input"
          style="outline"
          label="Ending Date"
          type="date"
          {...register("endDate", {
            required: "End Date is required",
          })}
          errors={errors}
        />
      </div>
      <div className="flex justify-end gap-2">
        <Button type="submit">Add Data</Button>
      </div>
    </form>
  );
}

export default Education;
