import React from "react";
import classNames from "classnames";

function Box({ children, className, overRideCurrentClassNames }) {
  return (
    <div
      className={classNames(
        {
          "p-5 bg-white shadow-sm rounded": !overRideCurrentClassNames,
        },
        className
      )}
    >
      {children}
    </div>
  );
}

function Background({ children, className, contentCenter }) {
  return (
    <div
      className={classNames(
        "w-full h-screen bg-off-white",
        {
          "flex justify-center items-center": contentCenter,
        },
        className
      )}
    >
      {children}
    </div>
  );
}

Box.Background = Background;

export default Box;
