import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";

import Home from "./containers/home";
import Login from "./containers/login";
import SuccessScreen from "./containers/successScreen";
import { googleAuthInit } from "./scripts/googleApi";

import "./index.css";
import useStorageProvider from "./hooks/storageProvider/useStorageProvider";
import { Toaster } from "react-hot-toast";
import FailureScreen from "./containers/failureScreen";

function App() {
  const { token } = useStorageProvider();

  useEffect(() => {
    googleAuthInit();
  }, []);

  return (
    <div className="w-full h-screen flex flex-col">
      <Routes>
        <Route path="/*" element={<Home token={token} />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/failure" element={<FailureScreen />} />
        <Route exact path="/success" element={<SuccessScreen />} />
      </Routes>
      <Toaster position="bottom-left" reverseOrder={false} />
    </div>
  );
}

export default App;
