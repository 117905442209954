import React from "react";
import classNames from 'classnames'

import Icon from "./assets/googleLogo";

function Google(props) {
  return (
    <button
      {...props}
      className={classNames(
        "flex w-64 justify-center transition-all duration-300 overflow-hidden ease-in-out items-center my-3 rounded border-2 border-blue-600 group mt-4",
        {
          'opacity-70': props?.disabled
        }
      )}
    >
      <span className="w-12 flex justify-end group-hover:w-64 transition-all duration-300 ease-in-out">
        <Icon />
      </span>
      <span className="w-full h-full transition-all duration-300 ease-in-out group-hover:translate-x-full overflow-hidden bg-blue-600 text-white p-2 font-medium">
        <p className="truncate overflow-hidden">Continue with Google</p>
      </span>
    </button>
  );
}

export default Google;
