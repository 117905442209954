import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import Form from "../../../../components/form";
import Button from "../../../../components/button";
import { Cross } from "../../../../components/icons";
import { updateUser } from "../../../../api/user";
import { toast } from "react-hot-toast";
import { invalidate } from "../../../../utils/queries";

function Achievements({ attributes, api, navigate }) {
  const { data, setFinishHandler, setLoading } = attributes;

  const [achievements, setAchievements] = useState(data.achievements || []);

  async function updateUserData() {
    setLoading(true);
    try {
      await updateUser(api, data);
      toast.success("Data Saved!!");
      await invalidate(["user"]);
      navigate("/home");
    } catch (e) {
      toast.error("Some error occured");
    }
    setLoading(false);
  }

  useEffect(() => {
    data.achievements = achievements;
    setFinishHandler(() => () => {
      updateUserData();
    });
  }, [achievements]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  return (
    <div className="h-full w-full">
      <ul className=" list-disc">
        {achievements.map(({ description }, idx) => (
          <li
            className="w-full p-2 rounded mb-2 bg-slate-50 flex justify-between items-center"
            key={idx}
          >
            {description}
            <div>
              <Cross
                className="hover:bg-gray-200 duration-300 transition-all cursor-pointer"
                onClick={() => {
                  setAchievements(achievements.filter((elem, i) => i !== idx));
                }}
              />
            </div>
          </li>
        ))}
      </ul>
      <form
        className="w-full rounded bg-slate-100 p-2"
        onSubmit={handleSubmit((d) => {
          setAchievements([...achievements, d]);
          reset();
        })}
      >
        <Form
          control="textarea"
          style="outline"
          label="Achievement"
          {...register("description", {
            required: "Description is required",
          })}
          className="max-h-60"
          helpText="Enter very basic description. You'll be able to change it through AI."
          errors={errors}
        />
        <div className="flex justify-end gap-2">
          <Button type="submit">Add Achievement</Button>
        </div>
      </form>
    </div>
  );
}

export default Achievements;
