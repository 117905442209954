export async function makePayment(api, data) {
  try {
    const result = await api.post("/checkout/create-session", data);
    return { data: result.data, error: null };
  } catch (e) {
    return {
      data: null,
      error: e,
    };
  }
}
