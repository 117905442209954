import React from "react";
import { Navigate, useLocation } from "react-router-dom";

import Box from "../../components/box";
import EditorHeader from "./components/header";
import { updateResume } from "../../api/resume";
import createApiInstance from "../../api";
import { toast } from "react-hot-toast";
import { invalidate } from "../../utils/queries";
import Viewer from "./components/viewer";
import SideBar from "./components/sideBar";
import { useQuery } from "@tanstack/react-query";
import { getTemplate } from "../../api/templates";
import WaitForData from "../../components/waitForData";

function Editor({ token }) {
  const { state } = useLocation();
  const api = createApiInstance(token);
  const templateQuery = useQuery(["template"], getTemplate);

  if (!state) {
    return <Navigate to={"/"} />;
  }

  return (
    <WaitForData items={[templateQuery]}>
      <Box.Background className={"w-full h-screen flex flex-col"}>
        <EditorHeader
          title={state.title}
          onSave={(val) => {
            updateResumeHandler({ title: val });
          }}
        />
        <div className="flex flex-1 h-0">
          <Viewer template={templateQuery.data?.data} />
          <SideBar />
        </div>
      </Box.Background>
    </WaitForData>
  );

  async function updateResumeHandler(data) {
    try {
      await updateResume(api, state._id, data);
      invalidate(["resume"]);
      toast.success("Data updated Successfully");
    } catch (e) {
      toast.error("Some Error occured. Try again later.");
    }
  }
}

export default Editor;
