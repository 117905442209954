import React from "react";

import errorIllustration from "./assets/error.gif";
import Text from "../../../../components/text";

function Public() {
  return (
    <div className="w-full h-full flex justify-center flex-col items-center lg:flex-row">
      <img
        className="w-44 md:w-2/4 lg:w-1/6"
        src={errorIllustration}
        alt="error illustration"
      />
      <span className="text-center lg:text-start p-2 lg:p-0">
        <Text.Heading className={"text-blue-800 mt-0"}>
          Still in development
        </Text.Heading>
        <Text className={"text-blue-600"}>
          {"Hey! This feature will soon be available. Stay tuned with us. ;)"}
        </Text>
      </span>
    </div>
  );
}

export default Public;
