import React, { useState } from "react";
import { GoogleLogout } from "react-google-login";

import Button from "../../../button";
import useStorageProvider from "../../../../hooks/storageProvider/useStorageProvider";
import Modal from "../../../modal";

function SignOut({ navigate }) {
  const { deleteToken } = useStorageProvider();
  const [isOpen, setOpen] = useState(false);
  return (
    <div>
      <Button
        style={"transparent"}
        className={"w-56"}
        onClick={(params) => {
          setOpen(true);
        }}
      >
        SignOut
      </Button>
      <Modal
        isOpen={isOpen}
        closeModal={() => {
          setOpen(true);
        }}
        ModalComponent={
          <div className="mt-3">
            <p className="text-sm">Do you want to proceed with sign out?</p>
            <GoogleLogout
              render={props=>{
                return(
                  <Button className={'w-full mt-2'} disabled={props.disabled} onClick={props.onClick} style={'danger'}>Yes</Button>
                )
              }}
              clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
              onLogoutSuccess={() => {
                deleteToken();
                navigate("/login");
              }}
            />
          </div>
        }
        showCloseButton
      />
    </div>
  );
}

export default SignOut;
