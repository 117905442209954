import React from "react";
import { GoogleLogin } from "react-google-login";
import { useNavigate } from "react-router-dom";

import useApi from "../../hooks/useApi";
import Box from "../../components/box";
import Text from "../../components/text";
import Button from "../../components/button";
import { BACKEND_URL } from "../../constants/urls";
import useStorageProvider from "../../hooks/storageProvider/useStorageProvider";

function Login() {
  const navigate = useNavigate();
  const api = useApi(BACKEND_URL);
  const { saveToken } = useStorageProvider();

  const handleLogin = async (res) => {
    const user = res.profileObj;
    const { data, error } = await api.post("/auth/token", {
      user: {
        email: user.email,
        name: user.name,
        nickname: String(user.email).split("@")[0],
        profile_picture: user.imageUrl,
      },
    });
    if (error) {
      navigate("/failure");
    } else {
      saveToken(data.token)
      setTimeout(() => {
        navigate("/");
      });
    }
  };

  const handleFailure = () => {
    navigate("/failure");
  };

  return (
    <Box.Background contentCenter>
      <Box>
        <Text.Heading>Sign In</Text.Heading>
        <GoogleLogin
          clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
          render={(renderProps) => (
            <Button.Google
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
            />
          )}
          buttonText="Login"
          onSuccess={handleLogin}
          onFailure={handleFailure}
          cookiePolicy={"single_host_origin"}
        />
      </Box>
    </Box.Background>
  );
}

export default Login;
