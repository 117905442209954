import classNames from "classnames";
import React from "react";
import { PulseLoader } from "react-spinners";

import Google from "./googleButton";
import Premium from "./premiumButton";

function Button({
  className,
  children,
  leftChild,
  size,
  googleButton,
  style,
  isLoading,
  disabled,
  spinColor,
  ...props
}) {
  const Tag = props?.href ? "a" : "button";
  return (
    <Tag
      className={classNames(
        "flex justify-center items-center transition-all ease-in-out duration-300 rounded",
        SIZE_TO_STYLE[size],
        STYLE[style],
        {
          "opacity-70 cursor-not-allowed": isLoading || disabled,
        },
        className
      )}
      disabled={disabled || isLoading}
      {...props}
    >
      {leftChild && <leftChild />}
      {isLoading ? (
        <PulseLoader color={spinColor ? spinColor : "white"} />
      ) : (
        children
      )}
    </Tag>
  );
}

const SIZE_TO_STYLE = {
  s: "px-2 py-1 text-xs",
  l: "px-4 py-2 text-sm",
  xl: "",
};

const STYLE = {
  danger: "bg-red-600 hover:bg-red-700 text-white",
  success: "bg-green-600 hover:bg-green-700 text-white",
  primary: "bg-blue-500 hover:bg-blue-600 text-white",
  transparent: "bg-transparent hover:bg-gray-100 text-blue-500",
  dark: "bg-slate-600 hover:bg-slate-700 text-white",
  outline_primary: "border-2 border-blue-500 hover:bg-blue-100 hover:border-blue-600 text-blue-600",
};

Button.defaultProps = {
  size: "l",
  type: "solid",
  style: "primary",
};

Button.Google = Google;
Button.Premium = Premium;

export default Button;
