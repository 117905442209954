import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";

import { AddCircle, Cross } from "../../../../components/icons";

import Form from "../../../../components/form";

function Skills({ attributes }) {
  const { setNextHandler, setActiveComponent, data, setData } = attributes;
  const [skills, setSkills] = useState(data?.skills || []);
  const skillRef = useRef();

  const addSkills = () => {
    const skill = skillRef.current?.value;
    if (!skill) {
      toast.error("Enter skill first");
    } else {
      setSkills([skill, ...skills]);
    }
  };

  useEffect(() => {
    setNextHandler(() => () => {
      if (skills.length >= 5) {
        data.skills = skills;
        setData(data);
        setActiveComponent("experience");
      } else {
        toast.error("There should be atleast 5 Skills");
      }
    });
  }, [skills]);

  return (
    <div className="w-full h-full flex flex-wrap items-center transition-all duration-300 ease-in-out">
      {skills.map((skill, idx) => {
        return (
          <Pill
            label={skill}
            key={idx}
            onDelete={() => {
              const newArr = skills.filter((skill, index) => index !== idx);
              setSkills(newArr);
            }}
          />
        );
      })}{" "}
      <div className="flex items-center gap-1">
        <Form
          placeholder="Add Skill"
          control="input"
          className=" w-min"
          ref={skillRef}
          onKeyDown={(e) => {
            e.key === "Enter" && addSkills();
          }}
        />
        <AddCircle
          size={25}
          className="text-blue-500 hover:text-blue-700 cursor-pointer transition-all duration-300 ease-in-out"
          onClick={addSkills}
        />
      </div>
    </div>
  );
}

const Pill = ({ label, onDelete }) => {
  return (
    <div className="w-max pl-4 pr-1 items-center py-1 rounded-full bg-indigo-500 text-white text-xs flex gap-1 mr-2 transition-all duration-300 ease-in-out">
      {label}
      <Cross
        className="bg-white rounded-full text-indigo-500 p-0.5 font-bold hover:bg-slate-100 transition-all ease-in-out duration-300 cursor-pointer"
        onClick={onDelete}
        size={15}
      />
    </div>
  );
};

export default Skills;
