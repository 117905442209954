import React, { useEffect, useState } from "react";
import { PropagateLoader } from "react-spinners";

import Box from "../../components/box";
import Header from "../../components/header";
import doneImage from "./assets/done.gif";
import Text from "../../components/text";
import useApi from "../../hooks/useApi";
import { BACKEND_URL } from "../../constants/urls";
import { useNavigate, useSearchParams } from "react-router-dom";

function SuccessScreen() {
  const [loading, setLoading] = useState(false);
  const api = useApi(BACKEND_URL);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  async function handleStatus() {
    setLoading(true);
    try {
      const { data } = await api.post(
        `/checkout/status?sessionId=${searchParams.get("session_id")}`
      );
      if (data.status !== "complete") {
        navigate("/failure");
      } else {
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      navigate("/failure");
    }
  }

  useEffect(() => {
    handleStatus();
  }, []);

  return (
    <Box.Background className={"flex flex-col"}>
      <Header />
      <div className="flex-1 flex justify-center items-center flex-col lg:flex-row">
        {loading ? (
          <PropagateLoader color="#F600CE" />
        ) : (
          <>
            <img src={doneImage} alt="done" className="w-96" />
            <span className=" w-80 lg:w-96 p-5 rounded-xl shadow-md bg-fuchsia-100 h-max">
              <Text.Heading className={"text-fuchsia-600"}>
                Hurray!! Payment is done.
              </Text.Heading>
              <Text className={" text-fuchsia-600 opacity-75 mb-2"}>
                Welcome to the world of MeCraft. Taste the success and unleash
                the power of Artificial Intelligence into documents.
              </Text>
              <span className="px-2 py-1 text-sm font-bold rounded-full bg-fuchsia-300 text-fuchsia-600 mt-5">
                You May Now Close this window
              </span>
            </span>
          </>
        )}
      </div>
    </Box.Background>
  );
}

export default SuccessScreen;
