import { ErrorMessage } from "@hookform/error-message";
import classNames from "classnames";
import React, { forwardRef } from "react";

const Form = forwardRef(function (
  {
    control,
    className,
    controlPropsClassName,
    errors,
    helpText,
    label,
    name,
    style,
    labelBold,
    disabled,
    ...props
  },
  ref
) {
  if (!control) {
    throw new Error("Form component must have ```control``` prop");
  }
  let Control;
  if (control === "input" || control === "textarea") {
    const Tag = control;
    Control = () => (
      <Tag
        {...props}
        disabled={disabled}
        ref={ref}
        name={name}
        className={classNames(
          "my-1 py-1 px-2 text-sm rounded bg-gray-100 focus:ring-2 focus:ring-blue-600",
          { "opacity-50 cursor-not-allowed": disabled },
          STYLE[style],
          controlPropsClassName
        )}
      />
    );
  }

  if (control === "checkbox") {
    Control = () => (
      <div className="flex flex-1">
        <input
          disabled={disabled}
          type="checkbox"
          {...props}
          ref={ref}
          name={name}
          className={classNames(
            "my-1 py-1 px-2 text-sm rounded bg-gray-100 focus:ring-2 focus:ring-blue-600",
            { "opacity-50 cursor-not-allowed": disabled },
            STYLE[style],
            controlPropsClassName
          )}
        />
      </div>
    );
  }

  return (
    <div
      className={classNames(
        "w-full flex flex-col my-1 text-slate-500",
        className
      )}
    >
      {label && (
        <span
          className={classNames("text-sm mb-0", {
            "font-semibold text-inherit": labelBold,
          })}
        >
          {label}
        </span>
      )}
      <Control />
      {helpText && (
        <span className="w-full text-end text-gray-400 text-xs italic">
          {helpText}
        </span>
      )}
      {errors && (
        <ErrorMessage
          name={name}
          errors={errors}
          render={({ message }) => (
            <span className="w-full text-xs text-right text-red-600 font-medium">
              {message}
            </span>
          )}
        />
      )}
    </div>
  );
});

export default Form;

const STYLE = {
  outline:
    "bg-transparent ring-2 focus:ring-3 focus:ring-slate-300 ring-slate-200 placeholder-slate-300",
};
