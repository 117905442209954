export async function getTemplate(){
    return new Promise((res,rej)=>{
        setTimeout(()=>{
            res({
                data: MOCK_TEMPLATE
            })
        },1000)
    })
}

const MOCK_TEMPLATE = {
    html: `<div class = 'heading' >Hello World</div>`,
    css: `
        .heading{
            background-color: red
        }
    `,
}