import React, { useEffect } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import Box from "../../components/box";
import WaitForData from "../../components/waitForData";
import { getUserProfile } from "../../api/user";
import createApiInstance from "../../api";
import Header from "../../components/header";

import PersonalScreen from "./screens/Personal";
import PublicScreen from "./screens/Public";
import { Tab } from "@headlessui/react";
import classNames from "classnames";
import Plans from "../plans";
import Editor from "../editor";
import { getResume } from "../../api/resume";
import Profile from "../profile";
import Onboarding from "../onboarding";

function Home({ token }) {
  const navigate = useNavigate();

  useEffect(() => {
    if (Boolean(token) === false) {
      navigate("/login");
    }
  }, []);

  return (
    <Routes>
      <Route path="/editor" element={<Editor token={token} />} />
      <Route
        path="/*"
        element={<HomeScreen navigate={navigate} token={token} />}
      />
    </Routes>
  );
}

function HomeScreen({ navigate, token }) {
  const api = createApiInstance(token);
  const userQuery = useQuery(["user"], () => getUserProfile(api));
  const resumeQuery = useQuery(["resume"], () => getResume(api));

  return (
    <WaitForData items={[userQuery]}>
      <Header user={userQuery?.data} navigate={navigate} />
      <Routes>
        <Route exact path="/" element={<Navigate to={"/home"} />} />
        <Route
          path="/home"
          element={
            <AppHome
              api={api}
              user={userQuery?.data}
              resumeQuery={resumeQuery}
              navigate={navigate}
            />
          }
          exact
        />
        <Route
          path="/plans"
          element={<Plans api={api} user={userQuery?.data} />}
          exact
        />
        <Route
          path="/profile"
          element={<Profile api={api} user={userQuery?.data} />}
          exact
        />
        <Route
          path="/onboarding"
          element={
            <Onboarding api={api} user={userQuery?.data} navigate={navigate} />
          }
          exact
        />
        <Route path="/*" element={<Navigate to={"/"} />} exact />
      </Routes>
    </WaitForData>
  );
}

function AppHome({ user, api, resumeQuery, navigate }) {
  useEffect(() => {
    if (user.onboarding_completed === false) {
      navigate("/onboarding");
    }
  }, []);
  return (
    <Tab.Group>
      <Box.Background
        className={"flex flex-col items-center overflow-y-scroll"}
      >
        <div className="w-full xl:max-w-[80%] flex flex-1 flex-col">
          <nav className="w-full p-2 sticky top-0 bg-off-white z-10">
            <Tab.List className="flex">
              {Object.keys(TABS).map((tab, index) => (
                <Tab
                  key={index}
                  className={({ selected }) =>
                    classNames(
                      "w-max rounded-lg py-2.5 px-5 text-sm font-medium leading-5 text-blue-600",
                      "focus:outline-none focus:ring-1 mr-1 last:mr-0 transition-all duration-300 ease-in-out",
                      selected
                        ? "bg-white shadow"
                        : "text-blue-100 hover:bg-blue-400/[0.12] hover:text-blue-700"
                    )
                  }
                >
                  {tab}
                </Tab>
              ))}
            </Tab.List>
          </nav>
          <Tab.Panels className="mt-1 flex flex-1">
            {Object.values(TABS).map(({ Screen }, index) => (
              <Tab.Panel className={"flex-1"} key={index}>
                <Screen api={api} user={user} resumeQuery={resumeQuery} />
              </Tab.Panel>
            ))}
          </Tab.Panels>
        </div>
      </Box.Background>
    </Tab.Group>
  );
}

const TABS = {
  Personal: {
    Screen: PersonalScreen,
  },
  Public: {
    Screen: PublicScreen,
  },
};

export default Home;
